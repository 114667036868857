import React from "react";
import Link from "next/link";
import removeMd from "remove-markdown";
import shortenText from "@/lib/shortenText";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ReactPlayer from "@/components/ReactModalPlayer/ReactPlayer";

const CourseCard = ({
  id,
  title,
  price,
  overview,
  profilePhoto,
  user,
  coursePreviewVideo,
  keyword,
  category,
  coursePreviewThumb,
  guestName,
}) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const searchAndReturn = (string, word) => {
    const index = string.toLowerCase().indexOf(word.toLowerCase());
    const start = Math.max(0, index - 70);
    const end = Math.min(string.length, index + 70);
    const sanitizedWord = word.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
    const highlightedText = string
      .slice(start, end)
      .replace(/[^a-zA-Z0-9._]/g, " ")
      .replace(new RegExp(sanitizedWord, "ig"), (match) => `<b>${match}</b>`);

    return {
      __html: (start === 0 ? "" : "... ") + highlightedText + (end === string.length ? "" : " ..."),
    };
  };

  const renderOverview = () => {
    if (keyword && overview.toLowerCase().includes(keyword.toLowerCase())) {
      return <p dangerouslySetInnerHTML={searchAndReturn(removeMd(overview), keyword)} />;
    }
    return <p>{shortenText(coursePreviewThumb || removeMd(overview), 200)}</p>;
  };

  return (
    <div className="single-courses-box">
      {coursePreviewVideo ? (
        <>
          <ReactPlayer autoPlay={true} open={!isOpen} url={coursePreviewVideo} toggleModal={() => setIsOpen(!isOpen)} />
          <div className="about-video-box">
            <div className="image">
              <img width={355} height={230} src={profilePhoto || "/images/default-profile.png"} alt="" />
            </div>
            <a
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(!isOpen);
              }}
              style={{ cursor: "pointer" }}
              className="video-btn popup-youtube"
            >
              <i className="flaticon-play"></i>
            </a>
          </div>
        </>
      ) : (
        <Link legacyBehavior href="/courses/[id]" as={`/courses/${id}`}>
          <a style={styles.anchorStyle}>
            <div className="courses-image">
              <div className="d-block image">
                <img src={profilePhoto || "/images/default-profile.png"} alt="" style={styles.hoverImg} />
              </div>
            </div>
          </a>
        </Link>
      )}
      <Link legacyBehavior href="/courses/[id]" as={`/courses/${id}`}>
        <a style={styles.anchorStyle}>
          <div className="courses-content">
            <div className="course-author d-flex align-items-center">
              <img
                src={user.profilePhoto || "/images/advisor/advisor3.svg"}
                className="rounded-circle"
                alt={user.name || "User"}
              />
              <span className="teacher-name">{user.name}</span>
              {price ? <strong className="price-box">${Math.floor(price)}</strong> : ""}{" "}
            </div>
            <h3>{title}</h3>
            {guestName && (
              <span className="sub-title">
                <i className="fi fi-sr-review"></i>
                {guestName}
              </span>
            )}
            {renderOverview()}
            <ul className="courses-box-footer d-flex justify-content-between">
              <li>
                <CategoryOutlinedIcon sx={{ marginBottom: "4px" }} fontSize="small" htmlColor="#b163f1" /> {category}
              </li>
            </ul>
          </div>
        </a>
      </Link>
    </div>
  );
};

export default CourseCard;

const styles = {
  hoverImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    display: "block",
  },
  anchorStyle: { display: "block", textDecoration: "none", color: "inherit", fontWeight: "inherit" },
};
